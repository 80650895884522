<template>
  <div>
    <div v-if="logged_in">
      <b-avatar variant="success" :text="initials" size="2.5rem"></b-avatar>
    </div>
    <div v-else>
      <b-avatar icon="person-circle" size="2.5rem"></b-avatar>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AvatarIcon',
  props: {
    userData: {
      type: Object,
      required: true
    },
    loggedInState: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    logged_in: function () {
      return this.userData.user_id || this.loggedInState
    },
    initials: function () {
      if (this.logged_in || this.loggeddInState) {
        const f = Array.from(this.userData.first_name)[0]
        const l = Array.from(this.userData.last_name)[0]
        const i = f + l
        return i
      } else {
        return ''
      }
    }
  }
}
</script>
